import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}))

function CardList(props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
      >
        {props.children}
      </Grid>
    </div>
  )
}

export default CardList