import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
// import ArticleBody from 'components/ArticleBody'
import CardSection from 'components/CardSection'
// import EmailIcon from '@material-ui/icons/MailOutline';
// import { Grid, Typography } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles';
import { ContactSection } from '../pages/contact'
// import UpdateIcon from '@material-ui/icons/Update';

// const useStyles = makeStyles(theme => ({
// root: {
//   margin: theme.spacing(4,0),
//   padding: theme.spacing(0),
// },
// grid: {
//   textAlign: 'center',
//   // border: '1px solid red',
//   margin: theme.spacing(0),
//   padding: theme.spacing(3),
// },
// icon: {
//   height: '3em',
//   width: '3em',
//   fontSize: '3em',
//   margin: theme.spacing(0),
//   padding: theme.spacing(2),
// },
// heading: {
//   margin: theme.spacing(4, 0),
//   fontSize: '2rem',
//   fontWeight: 600,
//   color: theme.palette.primary.main,
//   textAlign: 'center',
// },
// text: {
//   fontSize: '1.2rem',
//   lineHeight: 1.5,
//   margin: theme.spacing(0),
//   padding: theme.spacing(0, 2),
//   [theme.breakpoints.down('md')]: {
//     padding: theme.spacing(0, 1),
//   },
// }
// }));

// const buttons = [
//   {
//     text: 'Request a Quote',
//     to: '/contact',
//     icon: <EmailIcon />
//   },
// ]





function Contact() {
  // const classes = useStyles();
  return (
    <Fragment>
      <StaticQuery
        query={graphql`
        query {
          site {
            siteMetadata {
              phoneNumber
              email
              address
            }
          }
        }
      `}
        render={data => (
          <Fragment>
            <CardSection
              title="Contact Us"
              // buttons={buttons}
              scheme2
            >
              <ContactSection
                phone={data.site.siteMetadata.phoneNumber}
                email={data.site.siteMetadata.email}
                address={data.site.siteMetadata.address}
              />
            </CardSection>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

export default Contact