import React, { Fragment } from 'react'
import Layout from "components/Layout";
import Seo from "components/Seo";
import Header from 'components/Header'
import Iframe from 'components/Iframe'
import CardSection from 'components/CardSection'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { StaticQuery, graphql } from "gatsby"

const title = "Contact Us"
const description = "Let's Discuss Your Servicing or Repair Requirements"

export function ContactSection(props) {
  return (
    <Grid container spacing={5} justify="center" style={{ padding: '30px 20px' }}>
      <Grid item xs={12}>
        <Typography>With our fully equipped vehicle and experienced staff, we are ready to assist at a moments notice. Contact our team to discuss your options or to arrange scheduled maintenance or services, breakdown assistance and air-conditioning repairs.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography color="primary" align="center" variant="h5" gutterBottom>Contact Details</Typography><br />
        <Typography gutterBottom style={{ textDecoration: 'none', color: 'inherit' }} variant="h6" component="a" href={'tel:' + props.phone}>Phone: {props.phone} (24/7)</Typography><br /><br />
        <Typography gutterBottom style={{ textDecoration: 'none', color: 'inherit' }} variant="h6" component="a" href={'mailto:' + props.email}>Email: {props.email}</Typography><br /><br />
        <Typography gutterBottom style={{ textDecoration: 'none', color: 'inherit' }} variant="h6" component="a" href={'mailto:' + props.address}>Address: {props.address}</Typography><br /><br /><br /><br />
        <Typography color="primary" align="center" variant="h5" gutterBottom>Coverage Area</Typography><br />
        <Typography>Our service vehicle and team are fully equipped to provide diesel mechanic services to the South East Queensland Region – from the Darling downs to Western Downs, Brisbane and the Sunshine Coast.</Typography><br />
        <Iframe
          title="Coverage Map"
          width="100%"
          height="450"
          src="https://www.google.com/maps/d/u/0/embed?mid=1BrQ9cK4t-cJ-qUNq5sl8jV4Zv5JQ_eSa"
        />

      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Iframe
          title="Contact Form"
          width="100%"
          height="900"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdfD_yqIY1NdPmmQrN3P3Elo42ffBEiJtyn_Ke73H61LDOVXw/viewform?embedded=true"
        />
      </Grid>
    </Grid>
  )
}

export default () => (
  <Fragment>
    <StaticQuery
      query={graphql`
     query {
       site {
         siteMetadata {
           phoneNumber
           email
           address
         }
       }
     }
   `}
      render={data => (
        <Layout>
          <Seo
            title={title}
            description={description}
          />
          <Header
            title={title}
            subtitle={description}
          />
          <CardSection scheme2>
            <ContactSection
              phone={data.site.siteMetadata.phoneNumber}
              email={data.site.siteMetadata.email}
              address={data.site.siteMetadata.address}
            />
          </CardSection>
        </Layout>
      )}
    />
  </Fragment>
);



