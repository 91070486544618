import SearchIcon from '@material-ui/icons/Search';
import CardItem from 'components/CardItem';
import CardList from 'components/CardList';
import CardSection from 'components/CardSection';
import { graphql, StaticQuery } from "gatsby";
import * as React from 'react'

const buttons = [
  {
    text: 'Learn more about our services',
    to: '/services',
    icon: <SearchIcon />
  },
]

function ServicesPreview() {
  return (
    <CardSection
      scheme1
      title="Our Services"
      buttons={buttons}
    >
      <CardList>
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                sort: { order: ASC, fields: frontmatter___order }
                filter: { frontmatter: { type: { eq: "service" } } }
              ) {
                edges {
                  node {
                    frontmatter {
                      title
                      slug
                      metaDescription
                      tags
                      order
                      postImage {
                        childImageSharp {
                          fluid(maxWidth: 900, maxHeight: 440) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data =>
            data.allMarkdownRemark.edges.map(({ node }) => (
              <CardItem
                scheme1
                title={node.frontmatter.title}
                fluid={node.frontmatter.postImage.childImageSharp.fluid}
                tags={node.frontmatter.tags}
                slug={node.frontmatter.slug}
                description={node.frontmatter.metaDescription.substring(0, 100)}
                key={node.frontmatter.title}
                buttonText="Learn More"
              />
            ))
          }
        />
      </CardList>
    </CardSection>
  )
}

export default ServicesPreview
