import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: 'url("spinner.gif")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%'
    },
}));

function Iframe(props) {
    const classes = useStyles();
    return (
        <iframe
            className={classes.root}
            title={props.title}
            width={props.width}
            height={props.height}
            src={props.src}
            frameBorder="0"
            allowFullScreen
        >
            Loading
        </iframe>
    )
}

export default Iframe
