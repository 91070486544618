import * as React from "react"
import Layout from "components/Layout"
import { graphql } from "gatsby"
import Banner from '../homepage/banner'
import About from "../homepage/about"
import Seo from "components/Seo";
import Services from '../homepage/services-preview'
import Contact from '../homepage/contact'

export default ({ data }) => (
  <Layout>
    <Seo
      title={data.site.siteMetadata.title}
      description={data.site.siteMetadata.description}
    />
    <Banner />
    <About />
    <Services />
    <Contact />
  </Layout>
);


export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
