import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles';
import ArticleBody from 'components/ArticleBody'
import {
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    ListItemIcon,
    IconButton
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/MailOutline';
import SearchIcon from '@material-ui/icons/Search'
import EventIcon from '@material-ui/icons/Event';
import ClassIcon from '@material-ui/icons/Class';
import PublicIcon from '@material-ui/icons/Public';
import PersonIcon from '@material-ui/icons/Person';
import UpdateIcon from '@material-ui/icons/Update';

// import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        // margin: theme.spacing(2),
        padding: theme.spacing(0),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: 'flex-start',
        flexGrow: 1,
        height: "100%",
        // border: '1px solid red'
    },
    content: {
        alignContent: 'flex-start',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(0),

        // background: 'red'
    },
    image: {
        padding: theme.spacing(0),
        margin: theme.spacing(0)
    },
    info: {
        color: theme.palette.text.secondary,
        fontSize: '0.8rem',
        textTransform: 'capitalize',
        verticalAlign: 'middle',
        padding: theme.spacing(1, 0),
    },
    infoIcon: {
        fontSize: '1.2rem',
        marginRight: '3px',
        marginTop: '-3px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
    tags: {
        // color: theme.palette.text.secondary,
        fontSize: '0.8rem',
        fontWeight: '700!important',
        textTransform: 'capitalize',
        verticalAlign: 'middle',
        // padding: theme.spacing(1, 0),
    },
    link: {
        textDecoration: 'none',
    },
    title: {
        fontWeight: 700,
        padding: theme.spacing(2),
        margin: theme.spacing(0),
    },
    action: {
        fontWeight: 600,
        color: theme.palette.primary.main,
        borderColor: theme.palette.button.blue,
        margin: theme.spacing(0, 0, 0, -0.5),
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-start'
    },
}));

function Component(props) {
    const classes = useStyles();
    // const theme = useTheme();
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={props.lg ? props.lg : 4}
        // component={Card}

        // style={{ background: props.scheme2 ? theme.palette.scheme2.card : theme.palette.scheme1.card }}
        >
            <Card className={classes.card}>
                <CardMedia
                    children={<Img className={classes.image} style={{ height: '180px' }} fluid={props.fluid} />}
                    title={props.title}
                />
                <CardHeader
                    className={classes.title}
                    title={props.title}
                    subheader={props.description && props.description}
                />
                <CardContent className={classes.content}>
                    {props.region && <Typography className={classes.info}><PublicIcon className={classes.infoIcon} />{props.region}</Typography>}
                    {props.author && <Typography className={classes.info}><PersonIcon className={classes.infoIcon} />{props.author}</Typography>}
                    {props.category && <Link to={'/articles/' + props.category} className={classes.info}><ClassIcon className={classes.infoIcon} />{props.category}</Link>}
                    {props.date && (
                        props.updated ? <Typography className={classes.info}><UpdateIcon className={classes.infoIcon} />{props.updated}</Typography> : <Typography className={classes.info}><EventIcon className={classes.infoIcon} />{props.date}</Typography>
                    )}
                    {props.tags &&
                        <List dense={true}>
                            {props.tags &&
                                props.tags.map((item, i) => (
                                    <ListItem key={i}>
                                        <ListItemIcon>
                                            <CheckCircleIcon style={{color: 'green'}} />
                                        </ListItemIcon>
                                        <ListItemText
                                            className={classes.tags}
                                            primary={item}
                                        /></ListItem>
                                ))}
                        </List>
                    }
                    {props.content &&
                        <ArticleBody key={props.title} content={props.content} />
                    }
                </CardContent>
                <CardActions className={classes.action}>
                    {props.buttonText &&
                        <Button
                            // variant="outlined"
                            // size="small"
                            className={classes.action}
                            component={Link}
                            to={props.slug}
                            startIcon={<SearchIcon />}
                        >
                            {props.buttonText}
                        </Button>
                    }
                    {props.email &&
                        <IconButton aria-label="email" href={'mailto:' + props.email}>
                            <EmailIcon />
                        </IconButton>
                    }
                    {props.twitter &&
                        <IconButton aria-label="twitter" href={'https://twitter.com/' + props.twitter}>
                            <TwitterIcon />
                        </IconButton>
                    }
                </CardActions>
            </Card>
        </Grid>
    )
}

export default Component