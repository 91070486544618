
import * as React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  parent: {
    position: 'relative',
    backgroundColor: "#04040490"
  },
  fakeBgImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '30rem',
    zIndex: '-1',
    [theme.breakpoints.down('sm')]: {
      // height: '19rem',
      minWidth: '100%',
    },
  },
  content: {
    position: 'absolute',
    color: theme.palette.background.default,
    top: '50%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'left',
    zIndex: 100,
    padding: '8px',
  },
  title: {
    padding: '5px',
    fontSize: '2.8rem',
    lineHeight: '3rem',
    fontWeight: 'bold',
    textShadow: '0px 0px 2px #000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    padding: '5px',
    fontSize: '1.5rem',
    lineHeight: '1.75',
    fontWeight: 600,
    textShadow: '0px 0px 2px #000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
}));

function Banner() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <StaticQuery
        query={graphql`
     query {
       image: file(relativePath: { eq: "img/general/home-banner.png" }) {
         childImageSharp {
           fluid(maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
       }
       site {
         siteMetadata {
           homepageHeader
           homepageAbout
         }
       }
     }
   `}
        render={data => (
          <div className={classes.parent}>
            <Img
              className={classes.fakeBgImage}
              fluid={data.image.childImageSharp.fluid}
              title={"Banner"}
            />
            <div className={classes.content}>
              <Typography className={classes.title} component="h1" gutterBottom>
                {data.site.siteMetadata.homepageHeader}
              </Typography>
              <Typography className={classes.subtitle} component="h2" paragraph>
                {data.site.siteMetadata.homepageAbout}
              </Typography>
            </div>
          </div>
        )}
      />
    </React.Fragment>
  )
}

export default Banner