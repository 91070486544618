import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import ArticleBody from 'components/ArticleBody'
import CardSection from 'components/CardSection'
import EmailIcon from '@material-ui/icons/MailOutline';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

// import UpdateIcon from '@material-ui/icons/Update';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4,0),
    padding: theme.spacing(0),
  },
  grid: {
    textAlign: 'center',
    // border: '1px solid red',
    margin: theme.spacing(0),
    padding: theme.spacing(3),
  },
  icon: {
    height: '3em',
    width: '3em',
    fontSize: '3em',
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(4, 0),
    fontSize: '2rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  text: {
    fontSize: '1.2rem',
    lineHeight: 1.5,
    margin: theme.spacing(0),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    },
  }
}));

const buttons = [
  {
    text: 'Request a Quote',
    to: '/contact',
    icon: <EmailIcon />
  },
]

const gridList = [
  {
    icon: '',
    heading: 'Fully Mobile Service',
    text: 'We come to you anywhere within South East Queensland with our fully equipped vehicles and highly experienced team to reduce down time and costs associated with travel.',
  },
  {
    icon: '',
    heading: 'Experience',
    text: 'Our team has a wide variety of knowledge and hands on experience with almost all types of diesel vehicles and machinery. We have the skills and equipment to meet the needs of our customers promptly.',
  },
  {
    icon: '',
    heading: '24-hour Breakdown Service',
    text: 'With our fully equipped service vehicle and highly experienced mechanic, we are available on short notice, to promptly carry out repairs, 24 hours a day.',
  },
  {
    icon: '',
    heading: 'Reliable & Affordable',
    text: 'We are a family owned and operated business that strives for 100% customer satisfaction. We will come to your location, at your convenience, to carry out all of your diesel requirements.',
  },
]

function GridBox(props) {
  const classes = useStyles();
  return (
    <Grid
      className={classes.grid}
      item
      md={6}
      xs={12}
    >
      {/* <Icon className={classes.icon}>{props.icon}</Icon> */}
      <Typography component="h2" className={classes.heading}>{props.heading}</Typography>
      <Typography component="h3" className={classes.text}>{props.text}</Typography>
    </Grid>
  )
}

function About() {
  const classes = useStyles();
  return (
    <Fragment>
      <StaticQuery
        query={graphql`
        query {
          about: markdownRemark(frontmatter: { name: { eq: "about" } }) {
            html
            frontmatter {
              title
            }
          }
        }
      `}
        render={data => (
          <Fragment>
            <CardSection
              title="Why choose Peak Diesel Services?"
              buttons={buttons}
              scheme2
            >
              {/* <ImageBoxLeft
                align="justify"
                image={data.image1.childImageSharp.fluid}
                body={data.about.html}
                scheme2
              /> */}
              <ArticleBody
                content={data.about.html}
                align="center"
                variant="h6"
              />
              <Grid
                spacing={3}
                container
                className={classes.root}
              >
                {gridList && gridList.map(item => (
                  <GridBox
                    icon={item.icon}
                    heading={item.heading}
                    text={item.text}
                  />
                ))}
              </Grid>
            </CardSection>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

export default About